{
  "days": [
    {
      "label": "L",
      "value": "mon",
      "selected": true
    },
    {
      "label": "M",
      "value": "tue",
      "selected": false
    },
    {
      "label": "M",
      "value": "wed",
      "selected": true
    },
    {
      "label": "J",
      "value": "thu",
      "selected": false
    },
    {
      "label": "V",
      "value": "fri",
      "selected": true
    },
    {
      "label": "S",
      "value": "sat",
      "selected": false
    },
    {
      "label": "D",
      "value": "sun",
      "selected": false
    }
  ]
}
